.main-card {
  border-radius: 1rem;
  .card-body {
    padding: 1.5rem;

    h5.card-title {
      font-size: 1.5rem;
    }
  }
}

.btn-primary:disabled, .btn-primary.disabled {
  background-color: #EEF2F5;
  color: #939BA1;
  border-color: #939BA1;
}
